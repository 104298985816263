.contact-container {
    display: flex;
    flex-direction: column;
    padding: 5%;
    background-color: #f8f8f8;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh; 
    background-image: url('../images/Background_Contact.png'); */

  }
  
  .contact-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .contact-content {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;
    gap: 20px;
    position: relative; /* Enable positioning for overlap */
  }
  
  .contact-heading {
    font-size: 2.5em;
    font-weight: bold;
    /* color: #ff6600; */
  }
  
  .contact-subheading {
    font-style: italic;
    color: #333;
    width: 70%;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    width: 400px; /* Fixed width */
    height: 400px; /* Fixed height to match width */
    position: relative; /* Allows overlap adjustment */
    margin-right: -60px; /* Adjust left margin for overlap effect */
    z-index: 1; /* Ensure form is on top */
  }
  
  .contact-form h2 {
    color: #888;
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 12px 25px;
    /* background-color: #ff6600; */
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-image {
    max-width: 40%;
    margin-right: -20px; /* Adjust right margin for overlap effect */
    position: relative; /* Allows overlap adjustment */
  }
  
  .contact-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
      align-items: center;
      margin: 0;
      gap: 20px;
    }
  
    .contact-form {
      width: 360px; /* Smaller size for mobile */
      height: 300px;
      position: static; /* Reset overlap */
      margin: 0 auto;
    }
  
    .contact-image {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
.upload-map {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.upload-map h1 {
    text-align: center;
    color: #333;
}

.upload-map form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.upload-map label {
    font-weight: bold;
    color: #555;
}

.upload-map input[type="file"] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.upload-map button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-map button:hover {
    background-color: #0056b3;
}

.upload-map .alert {
    text-align: center;
    margin: 15px 0;
}

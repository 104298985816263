  .membership-plans-container {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column; 
  }
  
  .choose-plan-heading {
    text-align: left;
    color: white;
    font-size: 3rem;
  }
  .plans-wrapper {
    margin: 30px;
    display: flex;
    flex: 1; 
  }
  
  .left-container {
    flex: 1; 
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .small-mobile-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .right-container {
    max-width: 50%;
    flex: 1; 
    margin-right: 49px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
    align-items: end;
  }
  
  .card {
    max-width: 50%;
    background-color: white;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    /* flex: 1; 
    display: flex;
    flex-direction: column; */
  }
  
  .black-background {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
  


  
  .green-chip,
  .free-trial-chip {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  
  .green-chip {
    background-color: lightgreen;
  }
  
  .free-trial-chip {
    background-color: rgb(0, 0, 0);
    color: white;
  }
  
  .subheading {
    font-size: 1em;
    margin-bottom: 5px; 
  }
  
  .card-description {
    text-align: justify;
    margin-bottom: 5px; 
  }
  
  .price {
    font-size: 1.2em; 
    font-weight: bold;
  }
  


  @media (max-width: 900px) {
    .choose-plan-heading{
      text-align: left;
      color: white;
      font-size: 2rem;
    }
    .plans-wrapper {
      margin: 30px;
      display: flex;
      /* flex: 1;  */
      align-items: center;
      flex-direction: column;
    }



    .left-container {
      flex: 1; 
      max-width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .small-mobile-image {
      max-width: 100%;
      max-height: 100%;
    }
    
    .right-container {
      max-width: 100%;
      flex: 1; 
      margin-right: 0px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      align-items: center;
    }

    .card {
      max-width: 76%;
      background-color: white;
      padding: 15px;
      margin-bottom: 20px;
      margin-top: 47px;
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      /* flex: 1; 
      display: flex;
      flex-direction: column; */
    }
}

  

  
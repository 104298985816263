/* .container {
    font-family: Arial, sans-serif;
    color: white;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: 
        linear-gradient(#23004a55, #23004a99),
        url("/src/images/website-bg.jpg") no-repeat center center fixed; 
        
    background-size: cover;
}

.inner-container {
   
    background-size: cover;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.inner-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.inner-container p {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.exercise-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
    gap: 50px;
    width: 100%;
    max-width: 1200px;
    padding: 10px;
}

.exercise-box {
    background-color: rgba(61, 25, 107, 0.8); 
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
}

.exercise-box img {
    width: auto; 
    max-height: 150px; 
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 15px; 
    filter: brightness(0.8) sepia(0.3) hue-rotate(270deg);
}

.exercise-box h2 {
    margin: 0;
    font-size: 1.4em;
}

.exercise-box p {
    font-size: 1em;
    margin: 10px 0 0;
}

.exercise-box:hover {
    background-color: rgba(85, 43, 136, 0.8);
    transform: translateY(-10px);
}

.back-buttons {
    position: absolute;
    top: 27px;
    margin-top: 10px;
    margin-left: 10px;
}

.back-buttons:hover::before {
    content: "Back";
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: 50%; 
    left: calc(100% + 10px);
    transform: translateY(-50%);
    z-index: 999; 
}

.back-buttons img {
    width: 35px;
    filter: brightness(0) invert(1);
} */





.container {
    font-family: Arial, sans-serif;
    color: white;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: 
        linear-gradient(#23004a55, #23004a99),
        url("/src/images/website-bg.jpg") no-repeat center center fixed; 
        
    background-size: cover;
}

.inner-container {
    background-size: cover;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%; /* Ensure full width */
}

.inner-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 100%; /* Ensure full width */
}

.inner-container p {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 20px;
    width: 100%; /* Ensure full width */
}

.exercise-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
    gap: 50px;
    width: 100%;
    max-width: 1200px;
    padding: 10px;
}

.exercise-box {
    background-color: rgba(61, 25, 107, 0.8); 
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    transition: transform 0.3s, background-color 0.3s;
}

.exercise-box img {
    width: auto; 
    max-height: 150px; 
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 15px; 
    filter: brightness(0.8) sepia(0.3) hue-rotate(270deg);
}

.exercise-box h2 {
    margin: 0;
    font-size: 1.4em;
}

.exercise-box p {
    font-size: 1em;
    margin: 10px 0 0;
}

.exercise-box:hover {
    background-color: rgba(85, 43, 136, 0.8);
    transform: translateY(-10px);
}

.back-buttons {
    position: absolute;
    top: 20px; 
    left: 10px; 
    margin-top: 10px;
    margin-left: 10px;
    z-index: 1000; 
}

.back-buttons img {
    width: 35px;
    filter: brightness(0) invert(1);
}

.back-buttons:hover::before {
    content: "Back";
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: 50%; 
    left: calc(100% + 10px);
    transform: translateY(-50%);
    z-index: 999; 
}

/* Responsive styles */
@media (max-width: 768px) { /* iPad and below */
    .inner-container h1 {
        font-size: 1.8em; /* Adjust font size for smaller screens */
    }

    .inner-container p {
        font-size: 1em; /* Adjust font size for smaller screens */
    }

    .back-buttons {
        top: 15px;
        left: 5px; 
    }

    .exercise-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust grid for smaller screens */
        gap: 20px; /* Reduce gap between items */
    }

    .exercise-box img {
        max-height: 100px; /* Adjust image size */
    }
}

@media (max-width: 480px) { /* Mobile screens */
    .inner-container h1 {
        font-size: 1.6em; /* Further adjust font size */
    }

    .inner-container p {
        font-size: 0.9em; /* Further adjust font size */
    }

    .back-buttons img {
        width: 30px;
    }

    .exercise-box {
        padding: 10px; /* Adjust padding */
    }

    .exercise-box img {
        max-height: 80px; /* Adjust image size */
    }
}

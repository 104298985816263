.month-free-container {
    background-color: black;
    color: white;
    padding: 25px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px;
  }
  
  .text-section {
    max-width: 60%;
  }
  
  .text-section h1 {
    font-size: 2.7rem;
    margin: 0 0 10px 0;
  }
  
  .text-section p {
    font-size: 18px;
    margin: 0;
  }

  .month-free-h1 {
    font-size: 2.5rem;   /* Increased size for a larger look */
    font-weight: bold;   /* Keep it bold */
    margin: 30px 0;      /* Maintain margin for spacing */
    line-height: 1.3;    /* Keep readability */
}

@media (max-width: 768px) {
    .month-free-h1 {
        font-size: 1.9rem;  /* Bigger but responsive on smaller screens */
    }
}


  
  .button-section1 {
    display: flex;
    align-items: center;
  }
  
  .learn-more-button {
    background: none;
    margin-right: 20px;
    border: 2px solid white;
    color: white;
    padding: 15px 20px;
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .learn-more-button:hover {
    background-color: white;
    color: black;
  }
  
  @media (max-width: 800px) {
    .month-free-container {
      background-color: black;
      color: white;
      padding: 10px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 40px;
    }
    .text-section {
      max-width: 80%;
      margin: 20px 0px;
    }
    
    .text-section h1 {
      font-size: 2rem;
      margin: 0 0 10px 0;
    }
    
    .text-section p {
      font-size: 18px;
      margin: 0;
    }
    .learn-more-button {
      background: none;
      margin-right: 0px;
      margin-bottom: 20px;
      border: 2px solid white;
      color: white;
      padding: 14px 17px;
      border-radius: 30px;
      font-size: 14px;
      cursor: pointer;
    }
}

.month-free-p{
  text-align: justify;
}

.word-elitex{
  font-weight: bold;
}
.performance-sidebar {
    width: 200px;
    height: 100vh;
    background-color: white; /* Set background to white */
    padding: 20px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Keep items left-aligned */
    border-right: 1px solid rgb(240, 240, 240);
  }
  
  .performance-sidebar-logo {
    display: flex;
    justify-content: center; /* Center the logo */
    width: 100%;
    margin-bottom: 50px; /* Increase space between logo and menu */
  }
  
  .performance-sidebar-logo img {
    width: 100px; /* Adjust logo size */
    height: auto;
  }
  
  .performance-sidebar-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  
  .performance-sidebar-item {
    margin: 20px 0;
  }
  
  .performance-sidebar-item a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    padding-left: 10px; /* Add padding to ensure left alignment */
    display: block;
  }
  
  .performance-sidebar-item a:hover {
    color: #007bff;
  }
  
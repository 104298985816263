

#bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}


.sports {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
   
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
*,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}


.login-wrap{
	
	width:100%;
	margin:auto;
	max-width:525px;
	min-height:670px;
	position:relative;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:90px 70px 50px 70px;

	background:rgba(212, 199, 199, 0.25);

}
.login-html .sign-in-htm,
.login-html .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	backface-visibility:hidden;
	transition:all .4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
	display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-html .tab{
	font-size:22px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 15px 10px 0;
	display:inline-block;
	border-bottom:2px solid transparent;
	color: rgb(180, 165, 165);
	text-shadow: 1px 1px 2px #7a7a7a, 0 0 5px #6d6d6d, 0 0 10px #495872;

}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
	color:#fff;
	border-color:#1161ee;
	text-shadow: 1px 1px 2px #1161ee, 0 0 5px #1161ee, 0 0 10px #1161ee;
}
.login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	width:100%;
	color:#fff;
	display:block;
}
.login-form .group .input,
.login-form .group .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(237, 255, 172, 0.6);
}
.login-form .group input[data-type="password"]{
	-webkit-text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label{
	color:#eeeeee;
	font-size:12px;
}
.login-form .group .button{
	background:#1161ee;
}
.login-form .group label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-form .group .check:checked + label .icon{
	background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
	transform:rotate(0);
}


.hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgb(255, 255, 255);
}
.foot-lnk{
	text-shadow: 1px 1px 2px #1161ee, 0 0 5px #1161ee, 0 0 10px #1161ee;
	font-weight: bold;
	color: rgb(255, 255, 255);
	text-align:center;
}

.img-wrapper{
	margin-bottom: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
}
.img{
	position: absolute;
	top: -50px;
	width: 200px;
}

.message {
    text-align: center;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    display: none; /* Hidden by default */
}

.message.success {
    color: #28a745;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.message.error {
    color: #dc3545;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}



.back-button {
    position: absolute;
    top: 27px;
    margin-top: 10px;
    margin-left: 40px;
	width: 100vw;
	
}

.back-button:hover::before {
    content: "Back";
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: 50%; 
    left: calc(100% + 10px);
    transform: translateY(-50%);
    z-index: 999; 
}

.back-button img {
    width: 35px;
    filter: brightness(0) invert(1);
}

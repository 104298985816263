.strength{
    background: 
    linear-gradient(#23004a55, #23004a99), 
    url("https://media.idownloadblog.com/wp-content/uploads/2021/06/macOS-Monterey-wallpaper-Dark.jpg") no-repeat center center fixed; 
background-size: cover; 
color: white;
font-family: Arial, sans-serif;
margin: 0;
padding: 0;
height: 100vh;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
min-height: 100vh;
}